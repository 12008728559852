import React from 'react';
import styled, { css } from 'styled-components';

import { componentColors } from '@/layout/components/DealerWebFooter/constants';
import Link from '@/components/Link';
import AppstoreIcon from '@/assets/images/apps/appstore.png';
import GooglePlayIcon from '@/assets/images/apps/googleplay.png';
import { media } from '@/utils/mixin';

import { AppsProps } from './types';

function Apps({ appstore, googlePlay }: AppsProps) {
  if (!appstore && !googlePlay) {
    return null;
  }

  return (
    <Component>
      <Title>Мобильное приложение</Title>

      <List>
        {appstore && (
          <AppLink to={appstore} target="_blank">
            <img loading="lazy" src={AppstoreIcon} alt="Download iOS App" />
          </AppLink>
        )}

        {googlePlay && (
          <AppLink to={googlePlay} target="_blank">
            <img
              loading="lazy"
              src={GooglePlayIcon}
              alt="Download Google App"
            />
          </AppLink>
        )}
      </List>
    </Component>
  );
}

export default Apps;

const Component = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: ${componentColors.footerNavHeader};
`;

const List = styled.div`
  display: flex;
  margin-top: 15px;
`;

const AppLink = styled(Link)`
  position: relative;
  display: flex;
  width: 143px;
  height: 43px;

  ${media.tablet(
    css`
      width: 130px;
    `
  )}

  img {
    width: 100%;
    height: 100%;
  }

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.6;
  }
`;
