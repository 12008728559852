import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { componentColors } from '@/layout/components/DealerWebHeader/constants';

import { ModelCardProps } from './types';

function ModelCard({
  image,
  name,
  description,
  price,
  link,
  isNewTab,
  onClick,
}: ModelCardProps) {
  return (
    <Component
      to={link}
      target={isNewTab ? '_blank' : '_self'}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
        onClick?.(link, event)
      }
    >
      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />

      <Content>
        <Header>
          <Name>{name}</Name>
          <Description>{description}</Description>
        </Header>

        <Price>{price}</Price>
      </Content>
    </Component>
  );
}

export default ModelCard;

const Component = styled(Link)`
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 0.7;
  }
`;

const Image = styled(Picture)`
  padding-top: 50%;

  picture,
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const Header = styled.div``;

const Name = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${componentColors.headerModelCardName};
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  color: ${componentColors.headerModelCardDescription};
`;

const Price = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  color: ${componentColors.headerModelCardPrice};
`;
