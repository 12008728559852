import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import useSettingItem from '@/hooks/useSettingItem';
import { componentColors } from '@/layout/components/DealerWebFooter/constants';
import { getTheme } from '@/constants/theme';
import InstagramIcon from '@/assets/svg/socials/instagram.svg';
import FacebookIcon from '@/assets/svg/socials/facebook.svg';
import VKIcon from '@/assets/svg/socials/vk.svg';
import { ThemeValuesType } from '@/typings/common';

function Socials() {
  const theme = getTheme();
  const socialInstagram = useSettingItem('FOOTER_DEALER_WEB_INSTAGRAM');
  const socialFacebook = useSettingItem('FOOTER_DEALER_WEB_FACEBOOK');
  const socialVK = useSettingItem('FOOTER_DEALER_WEB_VK');

  if (!socialInstagram && !socialFacebook && !socialVK) {
    return null;
  }

  return (
    <Component>
      {socialInstagram && (
        <Social to={socialInstagram} target="_blank" theme={theme}>
          <InstagramIcon />
        </Social>
      )}

      {socialFacebook && (
        <Social to={socialFacebook} target="_blank" theme={theme}>
          <FacebookIcon />
        </Social>
      )}

      {socialVK && (
        <Social to={socialVK} target="_blank" theme={theme}>
          <VKIcon />
        </Social>
      )}
    </Component>
  );
}

export default Socials;

const Component = styled.div`
  display: flex;
`;

const Social = styled(Link)<{ theme: ThemeValuesType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-top: 30px;
  background: ${componentColors.footerSocialIconBackground};

  svg {
    width: 20px;
    height: 20px;

    path {
      transition: 0.3s;
      fill: ${componentColors.footerSocialIcon};
    }
  }

  &:not(:first-child) {
    margin-left: 20px;
  }

  &:hover {
    background: ${componentColors.footerSocialIconBackgroundHover};

    svg {
      path {
        fill: ${componentColors.footerSocialIconHover};
      }
    }
  }

  ${({ theme }) =>
    (theme === 'b2b' || theme === 'jac') &&
    css`
      border-radius: 50%;
    `}
`;
