import React from 'react';
import styled, { css } from 'styled-components';

import noCarUrl from '@/assets/svg/no-car.svg?url';
import { media } from '@/utils/mixin';

interface Props {
  infoType: 'noCars' | 'archived';
}

function NoCars({ infoType }: Props) {
  return (
    <Component>
      <Grid>
        <Main>
          <Title>
            {infoType === 'noCars'
              ? 'Информации о вашем автомобиле пока нет в личном кабинете пользователя «Атлант-М»'
              : 'В этом разделе нет активных автомобилей'}
          </Title>
          <Description>
            {infoType === 'noCars'
              ? 'Станьте нашим клиентом, и мы добавим всю информацию о вашем автомобиле (историю обслуживания, покупок и накопления баллов). Ждем вас!'
              : 'Возможно, Вы скрыли все автомобили и сейчас они находятся в архиве.'}
          </Description>
        </Main>
        <Aside>
          <img loading="lazy" src={noCarUrl} alt="" />
        </Aside>
      </Grid>
    </Component>
  );
}

export default NoCars;

const Component = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 50%;
  padding: 0 15px;
  margin-bottom: 30px;

  ${media.tablet(css`
    width: 100%;
    padding: 0;
  `)}
`;

const Grid = styled.div`
  display: flex;
  max-width: 460px;
`;

const Main = styled.div`
  flex: 1 1 auto;
  width: 100%;
  margin-right: auto;
  max-width: 300px;
`;

const Title = styled.h4`
  margin-bottom: 9px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.blue100};
`;

const Description = styled.p`
  margin-top: 9px;

  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.blue100};
`;

const Aside = styled.div`
  flex: 0 0 auto;
  width: 140px;
`;
