import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { componentColors } from '@/layout/components/DealerWebFooter/constants';

import Logo from './components/Logo';
import PrimaryText from './components/PrimaryText';
import Socials from './components/Socials';
import Menu from './components/Menu';
import SecondaryText from './components/SecondaryText';

function FooterTop() {
  return (
    <Component>
      <Inner>
        <Top>
          <Left>
            <Logo />
            <PrimaryText />
            <Socials />
          </Left>
          <Right>
            <Menu />
          </Right>
        </Top>

        <SecondaryText />
      </Inner>
    </Component>
  );
}

export default FooterTop;

const Component = styled.div`
  background: ${componentColors.footerTopBackground};
  padding: 40px 0 60px;

  ${media.tablet(css`
    padding: 40px 0;
  `)}
`;

const Inner = styled.div`
  max-width: 1920px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  display: flex;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  flex: 1 1 345px;
  max-width: 345px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Right = styled.div`
  flex: 1;
  margin-left: 70px;

  ${media.tablet(css`
    flex: auto;
    margin-left: 0;
    margin-top: 40px;
  `)}
`;
