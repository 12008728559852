import React from 'react';
import styled from 'styled-components';

import MenuItem from '@/layout/components/Header/components/MobileMenu/components/MenuItem';
import useSettingItem from '@/hooks/useSettingItem';
import { HeaderVariables } from '@/layout/components/Header/constants';
import { colors } from '@/constants/theme';
import Banner from '@/layout/components/Header/components/Banner';
import MenuColumn from '@/layout/components/Header/components/MenuColumn';

import { ModelsMenuProps } from './types';
import Menu from './components/Menu';

function ModelsMenu({ menuItem }: ModelsMenuProps) {
  const headerBrands = useSettingItem('HEADER_BRANDS');
  const hasHeaderBrands =
    menuItem.children.some((item) =>
      item.label.includes(HeaderVariables.Brands)
    ) &&
    headerBrands &&
    headerBrands.length > 0;
  const banner = menuItem.children.find((item) =>
    item.label.includes(HeaderVariables.Banner)
  );
  const menu = menuItem.children.filter(
    (item) =>
      !item.label.includes(HeaderVariables.Brands) &&
      !item.label.includes(HeaderVariables.Banner)
  );

  return (
    <MenuItem
      label={menuItem.label.replace(HeaderVariables.Models, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      {hasHeaderBrands &&
        headerBrands.map(({ section, items }, index) => (
          <MenuColumnWrapper key={index}>
            <MenuColumn
              title={section ?? ''}
              menu={
                items && items.length !== 0
                  ? items.map(({ image, name, link, isNewTab }) => ({
                      image,
                      title: name ?? '',
                      link: link ?? '',
                      isNewTab,
                    }))
                  : []
              }
            />
          </MenuColumnWrapper>
        ))}

      <Bottom>
        {menu && menu.length > 0 && <Menu items={menu} />}{' '}
        {banner && (
          <BannerWrapper>
            <Banner
              label={banner.label.replace(HeaderVariables.Banner, '').trim()}
              link={banner.link ?? ''}
              isNewTab={banner.isNewTab}
            />
          </BannerWrapper>
        )}
      </Bottom>
    </MenuItem>
  );
}

export default ModelsMenu;

const MenuColumnWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${colors.gray200};
  }
`;

const Bottom = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${colors.gray200};
`;

const BannerWrapper = styled.div`
  margin-top: 16px;
`;
