import React from 'react';
import styled from 'styled-components';

import FooterPurchase from './FooterPurchase';
import Bottom from './components/Bottom';
import Main from './components/Main';

interface Props {
  isPurchasePage?: boolean;
  withoutFooterTopBottom?: boolean;
  withoutBottomFooter?: boolean;
}

function Footer({
  isPurchasePage,
  withoutFooterTopBottom,
  withoutBottomFooter,
}: Props) {
  if (isPurchasePage) {
    return <FooterPurchase />;
  }

  return (
    <FooterContainer id="footer">
      <Main withoutFooterTopBottom={withoutFooterTopBottom} />
      {!withoutBottomFooter && <Bottom />}
    </FooterContainer>
  );
}

export default Footer;

const FooterContainer = styled.footer``;
