import React from 'react';
import styled from 'styled-components';

import { isNotNullish } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import { getIsUserAuthorized } from '@/store/reducers/auth';
import NoCars from '@/layout/components/Header/components/User/components/NoCars';
import Dropdown from '@/layout/components/Header/components/Dropdown';
import {
  getCabinetPersonalData,
  getCabinetProfile,
} from '@/store/reducers/cabinet';
import SignIn from '@/layout/components/Header/components/SignIn';

import Promo from './components/Promo';
import Actions from './components/Actions';
import Slider from './components/Slider';
import Menu from './components/Menu';
import Logout from './components/Logout';

function User() {
  const isUserAuthorized = useTypedSelector(getIsUserAuthorized);
  const profile = useTypedSelector(getCabinetProfile);
  const personalData = useTypedSelector(getCabinetPersonalData);

  const slides = profile?.cars && profile.cars.length > 0 ? profile.cars : [];
  const isUserCars = slides.length > 0;

  if (!isUserAuthorized) {
    return <SignIn />;
  }

  return (
    <Component>
      <Dropdown
        label={`${personalData?.name ?? ''} ${personalData?.lastName ?? ''}`}
      >
        <Content>
          {isUserCars ? (
            <Slider slides={slides} />
          ) : profile?.availability?.cars ? (
            <NoCars infoType="archived" />
          ) : (
            <NoCars infoType="noCars" />
          )}

          <Menu
            items={[
              {
                label: 'Личные данные',
                url: '/personal',
              },
            ]}
          />

          <Menu
            items={[
              profile?.availability?.cars
                ? {
                    label: 'Мои автомобили',
                    url: '/personal/cars',
                  }
                : undefined,
              profile?.availability?.purchasesCars ||
              profile?.availability?.purchasesOther ||
              profile?.availability?.purchasesInsurances ||
              profile?.availability?.purchasesServices
                ? {
                    label: 'Мои покупки',
                    url: '/personal/purchases',
                  }
                : undefined,
              profile?.availability?.requests
                ? {
                    label: 'Мои заявки',
                    url: '/personal/requests',
                  }
                : undefined,
              profile?.availability?.bonuses
                ? {
                    label: 'Мои бонусы',
                    url: '/personal/bonuses',
                  }
                : undefined,
            ].filter(isNotNullish)}
          />

          <Logout />
          <Actions />
          <Promo />
        </Content>
      </Dropdown>
    </Component>
  );
}

export default User;

const Component = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;
  margin-left: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
