import React from 'react';
import styled from 'styled-components';

import { HeaderVariables } from '@/layout/components/Header/constants';
import Dropdown from '@/layout/components/Header/components/Dropdown';
import MenuColumn from '@/layout/components/Header/components/MenuColumn';
import { bodyIconMap } from '@/constants/icons';

import { CarsDropdownProps } from './types';

function CarsDropdown({ menuItem }: CarsDropdownProps) {
  return (
    <Dropdown
      label={menuItem.label.replace(HeaderVariables.Cars, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      <Inner>
        {menuItem.children.map((item, index) => (
          <MenuColumn
            key={index}
            title={item.label}
            menu={item.children.map((item) => ({
              title: item.label,
              link: item.link ?? '',
              isNewTab: item.isNewTab,
              icon: bodyIconMap[item.label],
            }))}
            columns={index + 1 !== menuItem.children.length ? 2 : 1}
          />
        ))}
      </Inner>
    </Dropdown>
  );
}

export default CarsDropdown;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;
