import { useRouter } from 'next/router';

import { PageFullType } from '@tager/web-modules';
import { Nullable } from '@tager/web-core';

import { getCurrentPath } from '@/utils/common';
import { selectPageByPath } from '@/store/reducers/tager/pages';
import { useTypedSelector } from '@/store/store';

function useLayoutPage<P extends PageFullType>(): Nullable<P> {
  const router = useRouter();

  const currentPath = getCurrentPath(router.route, router.query.slug);

  return useTypedSelector((state) =>
    selectPageByPath<P>(state, currentPath)
  ) as Nullable<P>;
}

export default useLayoutPage;
