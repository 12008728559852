import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { componentColors } from '@/layout/components/DealerWebFooter/constants';

import FooterInfo from './components/FooterInfo';
import FooterCopyright from './components/FooterCopyright';

function FooterBottom() {
  return (
    <Component id="bottom-footer">
      <Container>
        <Inner>
          <FooterInfo />
          <FooterCopyright />
        </Inner>
      </Container>
    </Component>
  );
}

export default FooterBottom;

const Component = styled.div`
  background: ${componentColors.footerBottomBackground};
`;

const Container = styled.div`
  max-width: 1920px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 30px;
  border-top: 1px solid ${componentColors.footerBottomLine};

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;
