import React from 'react';
import styled from 'styled-components';

import { HeaderVariables } from '@/layout/components/Header/constants';
import MenuColumn from '@/layout/components/Header/components/MenuColumn';
import MenuItem from '@/layout/components/Header/components/MobileMenu/components/MenuItem';
import { colors } from '@/constants/theme';
import useSettingItem from '@/hooks/useSettingItem';
import InfoLink from '@/layout/components/Header/components/InfoLink';

import { ServiceMenuProps } from './types';

function ServiceMenu({ menuItem }: ServiceMenuProps) {
  const headerServices = useSettingItem('HEADER_SERVICES');
  const headerServiceOnlineHelp = useSettingItem('HEADER_SERVICE_ONLINE_HELP');

  const hasHeaderServices = menuItem.children.some((item) =>
    item.label.includes(HeaderVariables.Services)
  );
  const serviceOnline = menuItem.children.find((item) =>
    item.label.includes(HeaderVariables.ServiceOnline)
  );

  return (
    <MenuItem
      label={menuItem.label.replace(HeaderVariables.Service, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      {hasHeaderServices &&
        headerServices &&
        headerServices.length > 0 &&
        headerServices.map(({ section, items }, index) => (
          <MenuColumnWrapper key={index}>
            <MenuColumn
              title={section ?? ''}
              menu={
                items && items.length !== 0
                  ? items.map(({ image, name, link, isNewTab }) => ({
                      image,
                      title: name ?? '',
                      link: link ?? '',
                      isNewTab,
                    }))
                  : []
              }
            />
          </MenuColumnWrapper>
        ))}

      {serviceOnline && (
        <MenuColumnWrapper>
          <InfoLink
            label={serviceOnline.label
              .replace(HeaderVariables.ServiceOnline, '')
              .trim()}
            link={serviceOnline.link ?? ''}
            info={headerServiceOnlineHelp ?? ''}
            isNewTab={serviceOnline.isNewTab}
          />
        </MenuColumnWrapper>
      )}
    </MenuItem>
  );
}

export default ServiceMenu;

const MenuColumnWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${colors.gray200};
  }
`;
