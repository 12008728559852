import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { componentColors } from '@/layout/components/DealerWebHeader/constants';
import { media } from '@/utils/mixin';

import { MenuProps } from './types';

function Menu({ menu }: MenuProps) {
  return (
    <Component>
      {menu.map(({ title, menu }, index) => (
        <MenuColumn key={index}>
          <Title>{title}</Title>

          <Body>
            {menu.map((item, index) => (
              <MenuLink
                key={index}
                to={item.link}
                target={item.isNewTab ? '_blank' : '_self'}
                onClick={(event) => item.onClick?.(item.link, event)}
              >
                {item.title}
              </MenuLink>
            ))}
          </Body>
        </MenuColumn>
      ))}
    </Component>
  );
}

export default Menu;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

const MenuColumn = styled.div``;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${componentColors.headerNav};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const MenuLink = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  color: ${componentColors.headerNav};

  &:hover {
    color: ${componentColors.headerNavHover};
  }

  &:not(:first-child) {
    margin-top: 16px;
  }
`;
