import React from 'react';
import styled from 'styled-components';

import { componentColors } from '@/layout/components/DealerWebFooter/constants';
import useSettingItem from '@/hooks/useSettingItem';

function PrimaryText() {
  const text = useSettingItem('FOOTER_DEALER_WEB_TOP_TEXT_PRIMARY');

  if (!text) {
    return null;
  }

  return <Component dangerouslySetInnerHTML={{ __html: text }} />;
}

export default PrimaryText;

const Component = styled.div`
  font-size: 16px;
  line-height: 21px;
  margin-top: 20px;
  color: ${componentColors.footerTopText};
`;
