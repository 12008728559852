import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { LocationProps } from './types';
import LocationDesktop from './components/LocationDesktop';
import LocationMobile from './components/LocationMobile';

function Location({ isMobile }: LocationProps) {
  return (
    <Component>
      {!isMobile ? <LocationDesktop /> : <LocationMobile />}
    </Component>
  );
}

export default Location;

const Component = styled.div`
  margin-left: 10px;

  ${media.tablet(css`
    margin: 0 5px;
  `)}
`;
