import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';
import { Nullable } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import Link from '@/components/Link';
import {
  MenuAliasType,
  selectMenuItemListByAlias,
} from '@/store/reducers/tager/menus';
import useSettingItem from '@/hooks/useSettingItem';
import Dealers from '@/layout/components/Footer/components/Dealers/Dealers';
import { media } from '@/utils/mixin';
import CallbackForm from '@/components/modals/CallbackForm';
import { getPhoneLink } from '@/utils/common';
import { usePhoneNumber } from '@/hooks/usePhoneNumber';
import { colors } from '@/constants/theme';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { googleEvent } from '@/utils/events';
import { FooterVariables } from '@/layout/components/Footer/constants';
import { handleSubmitCallbackForm } from '@/utils/forms';

import { FooterContainer } from '../FooterContainer';

import Socials from './components/Socials';
import Apps from './components/Apps';
import Pay from './components/Pay';

type Props = {
  title?: string;
  text?: string;
  isPurchasePage?: boolean;
  menuAlias?: MenuAliasType;
  withoutFooterTopBottom?: boolean;
};

function Main({
  title,
  text,
  isPurchasePage,
  menuAlias = 'footer',
  withoutFooterTopBottom,
}: Props) {
  const openModal = useModal();

  const menu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, menuAlias)) ??
    [];
  const defaultDisclaimer = useSettingItem('FOOTER_DISCLAIMER');
  const footerText = text ? text : defaultDisclaimer;
  const phoneNumber = usePhoneNumber();
  const phoneCallbackGoogleEvent = useSettingItem(
    'PHONE_CALLBACK_GOOGLE_EVENT'
  );
  const phoneClickGoogleEvent = useSettingItem('PHONE_CLICK_GOOGLE_EVENT');

  function handleCallClick(event: React.MouseEvent) {
    event.preventDefault();
    openModal(
      CallbackForm,
      {
        request: (values) =>
          handleSubmitCallbackForm(values, {
            type: phoneCallbackGoogleEvent ?? '',
          }),
        title: 'Заказать обратный звонок',
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  }

  const onPhoneClick = () => {
    if (phoneClickGoogleEvent) {
      googleEvent(phoneClickGoogleEvent);
    }
  };

  return (
    <Component>
      <FooterContainer width={1280}>
        {title && <Title dangerouslySetInnerHTML={{ __html: title ?? '' }} />}
        <Row>
          {menu.map((menuColumn, index) => {
            const variableIndex = menuColumn.label.indexOf(':');
            const menuTitle =
              variableIndex !== -1
                ? menuColumn.label.substring(0, variableIndex).trim()
                : menuColumn.label;
            const menuView = menuColumn.label.substring(variableIndex);

            return (
              <Col
                key={index}
                colWidth={
                  menuView === FooterVariables.Dealers
                    ? '520px'
                    : isPurchasePage
                    ? '50%'
                    : null
                }
                tabletFullWidth={menuView === FooterVariables.Dealers}
              >
                <SimpleMenu>
                  <SimpleMenuHeader
                    to={menuColumn.link}
                    target={menuColumn.isNewTab ? '_blank' : '_self'}
                  >
                    {menuTitle}
                  </SimpleMenuHeader>

                  {menuView === FooterVariables.Dealers ? (
                    <Dealers />
                  ) : menuColumn.children ? (
                    <SimpleMenuList isColumn={index !== 0}>
                      {menuColumn.children.map((menuChildItem, index) => {
                        let label = menuChildItem.label;
                        let link = menuChildItem.link;

                        if (menuChildItem.label === FooterVariables.Phone) {
                          label = phoneNumber ?? '';
                          link = getPhoneLink(phoneNumber);
                        }

                        return (
                          <SimpleMenuItem key={index}>
                            {link === FooterVariables.Call ? (
                              <SimpleMenuLinkButton onClick={handleCallClick}>
                                {label}
                              </SimpleMenuLinkButton>
                            ) : (
                              <SimpleMenuLink
                                to={link ?? ''}
                                target={
                                  menuChildItem.isNewTab ? '_blank' : '_self'
                                }
                                onClick={() =>
                                  link &&
                                  link.startsWith('tel:+') &&
                                  onPhoneClick()
                                }
                              >
                                {label}
                              </SimpleMenuLink>
                            )}
                          </SimpleMenuItem>
                        );
                      })}
                    </SimpleMenuList>
                  ) : null}

                  {menuView === FooterVariables.Contacts ? (
                    <ExternalApps>
                      <Socials />
                      <Apps />
                    </ExternalApps>
                  ) : null}
                </SimpleMenu>
              </Col>
            );
          })}
        </Row>
        {!withoutFooterTopBottom && (
          <>
            <Separator />

            <Sub>
              <SubGrid>
                <Info dangerouslySetInnerHTML={{ __html: footerText ?? '' }} />
                <SubPay>
                  <Pay isPurchasePage={isPurchasePage} />
                </SubPay>
              </SubGrid>
            </Sub>
          </>
        )}
      </FooterContainer>
    </Component>
  );
}

export default Main;

const Component = styled.div`
  background: ${(props) => props.theme.black200_1};
  color: ${colors.white};
  border-top: 1px solid ${colors.black100};
  padding: 30px 0;

  ${media.mobileUp(
    css`
      padding: 40px 0;
    `
  )};

  @media (min-width: 1200px) {
    padding: 60px 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  ${media.mobile(css`
    display: block;
    margin: 0;
  `)}
`;

const Title = styled.div`
  text-align: center;
  font-size: 19px;
  line-height: 28px;
  color: ${colors.white};
  margin-top: -20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.blue600};
  margin-bottom: 20px;

  a {
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom-color: ${colors.white};
    }
  }

  ${media.tablet(css`
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
  `)}
`;

const Col = styled.div<{
  colWidth: Nullable<string>;
  tabletFullWidth: boolean;
}>`
  max-width: 100%;
  padding: 0 10px;
  margin: 0 0 30px;
  flex: 0 0 auto;
  width: ${(props) => (props.colWidth ? props.colWidth : '16.6666666667%')};

  ${media.tablet(css`
    width: 33.3333333%;
  `)}

  ${media.mobile(css`
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  `)}

  ${({ tabletFullWidth }) =>
    tabletFullWidth &&
    css`
      ${media.tablet(css`
        width: 100%;
        order: -1;
      `)}
    `};
`;

const SimpleMenu = styled.div``;

const SimpleMenuHeader = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  color: ${colors.white};

  &:hover {
    color: ${colors.white};
    border-bottom: 1px solid ${colors.white};
  }
`;

const SimpleMenuList = styled.ul<{ isColumn: boolean }>`
  margin-top: 16px;

  ${({ isColumn }) =>
    css`
      ${media.mobile(css`
        display: grid;
        grid-template-columns: repeat(${isColumn ? 1 : 2}, 1fr);
        gap: 0 15px;
      `)}
    `}
`;

const SimpleMenuItem = styled.li`
  font-size: 14px;
  line-height: 20px;

  &:not(:last-child) {
    margin: 0 0 16px;
  }
`;

const ExternalApps = styled.div`
  margin-top: 35px;
`;

const SimpleMenuLink = styled(Link)`
  color: ${colors.white};
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${colors.white};
    border-bottom: 1px solid ${colors.white};
  }
`;

const SimpleMenuLinkButton = styled.button`
  color: ${colors.white};
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${colors.white};
    border-bottom: 1px solid ${colors.white};
  }
`;

const Separator = styled.hr`
  margin: 30px 0 20px 0;
  border: none;
  border-top: 1px solid ${colors.blue600};

  ${media.mobileUp(
    css`
      margin: 30px 0;
    `
  )}
`;

const Sub = styled.div`
  margin-top: 20px;

  ${media.mobileUp(
    css`
      margin-top: 30px;
    `
  )}
`;

const SubGrid = styled.div`
  ${media.mobileUp(
    css`
      display: flex;
      margin: 0 -15px;
      align-items: center;
    `
  )}
`;

const Info = styled.div`
  flex: 0 1 auto;
  color: ${colors.blue600};
  font-size: 12px;
  line-height: 20px;

  a {
    color: ${colors.blue600};
    text-decoration: none;
    border-bottom: 1px solid ${colors.blue600};

    &:hover {
      color: ${colors.white};
      text-decoration: none;
      border-bottom: 1px solid transparent;
    }
  }

  ${media.mobileUp(
    css`
      max-width: 50%;
      padding: 0 15px;
    `
  )}
`;

const SubPay = styled.div`
  margin-top: 20px;

  ${media.mobileUp(
    css`
      padding: 0 15px;
      text-align: right;
      margin-top: 0;
    `
  )}
`;
