export const calculateFinalPercent = (percent: number) => {
  if (
    percent % 20 === 1 ||
    percent % 20 === 2 ||
    percent % 20 === 3 ||
    percent % 20 === 4 ||
    percent % 20 === 5 ||
    percent % 20 === 6
  ) {
    return percent + 2;
  }
  return percent;
};
