import React from 'react';

import { isStringGuard } from '@tager/web-core';

import { getTheme } from '@/constants/theme';
import AtlantmSecondaryLogo from '@/assets/svg/preloader/atlantm-secondary-logo.svg';
import AtlantmPrimaryLogo from '@/assets/svg/preloader/atlantm-primary-logo.svg';
import B2bPrimaryLogo from '@/assets/svg/preloader/b2b-primary-logo.svg';
import B2bSecondaryLogo from '@/assets/svg/preloader/b2b-secondary-logo.svg';
import HisunPrimaryLogo from '@/assets/svg/preloader/hisun-primary-logo.svg';
import HisunSecondaryLogo from '@/assets/svg/preloader/hisun-secondary-logo.svg';
import JacPrimaryLogo from '@/assets/svg/preloader/jac-primary-logo.svg';
import JacSecondaryLogo from '@/assets/svg/preloader/jac-secondary-logo.svg';
import SollersPrimaryLogo from '@/assets/svg/preloader/sollers-primary-logo.svg';
import SollersSecondaryLogo from '@/assets/svg/preloader/sollers-secondary-logo.svg';
import JetourPrimaryLogo from '@/assets/svg/preloader/jetour-primary-logo.svg';
import JetourSecondaryLogo from '@/assets/svg/preloader/jetour-secondary-logo.svg';
import ZeekrPrimaryLogo from '@/assets/svg/preloader/zeekr-primary-logo.svg';
import ZeekrSecondaryLogo from '@/assets/svg/preloader/zeekr-secondary-logo.svg';

import * as S from './Preloader.style';

export function isPreloaderEnabled() {
  const isEnabledEnv = process.env.NEXT_PUBLIC_SPLASHSCREEN_ENABLED;

  return (
    isStringGuard(isEnabledEnv) &&
    (isEnabledEnv.toLowerCase() === 'true' || isEnabledEnv === '1')
  );
}

export function getPreloaderLogos() {
  const theme = getTheme();

  switch (theme) {
    case 'main':
      return (
        <React.Fragment>
          <AtlantmPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <AtlantmSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'b2b':
      return (
        <React.Fragment>
          <B2bPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <B2bSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'hisun':
      return (
        <React.Fragment>
          <HisunPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <HisunSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'jac':
      return (
        <React.Fragment>
          <JacPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <JacSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'sollers':
      return (
        <React.Fragment>
          <SollersPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <SollersSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'jetour':
      return (
        <React.Fragment>
          <JetourPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <JetourSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    case 'zeekr':
      return (
        <React.Fragment>
          <ZeekrPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <ZeekrSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <AtlantmPrimaryLogo id="no-color-logo" />
          <S.WrapperIconLogo>
            <AtlantmSecondaryLogo id="color-logo" />
          </S.WrapperIconLogo>
        </React.Fragment>
      );
  }
}
