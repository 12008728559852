import React from 'react';
import styled, { css } from 'styled-components';

import { getTheme } from '@/constants/theme';
import B2bLogo from '@/assets/svg/dealer-web/b2b-logo-white.svg';
import HisunLogo from '@/assets/svg/dealer-web/hisun-logo.svg';
import JacLogo from '@/assets/svg/dealer-web/jac-logo-white.svg';
import SollersLogo from '@/assets/svg/dealer-web/sollers-logo-white.svg';
import JetourLogo from '@/assets/svg/dealer-web/jetour-logo-white.svg';
import ZeekrLogo from '@/assets/svg/dealer-web/zeekr-logo-white.svg';
import { media } from '@/utils/mixin';

function Logo() {
  const theme = getTheme();

  switch (theme) {
    case 'b2b':
      return (
        <Component>
          <B2bLogo />
        </Component>
      );
    case 'hisun':
      return (
        <Component>
          <HisunLogo />
        </Component>
      );
    case 'jac':
      return (
        <Component>
          <JacLogo />
        </Component>
      );
    case 'sollers':
      return (
        <Component>
          <SollersLogo />
        </Component>
      );
    case 'jetour':
      return (
        <Component>
          <JetourLogo />
        </Component>
      );
    case 'zeekr':
      return (
        <Component>
          <ZeekrLogo />
        </Component>
      );
    default:
      return null;
  }
}

export default Logo;

const Component = styled.div`
  svg {
    width: 157px;
    height: 45px;

    ${media.tablet(css`
      width: 140px;
      height: 40px;
    `)}
  }
`;
