import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { MenuColumnProps } from './types';

function MenuColumn({ title, menu, columns = 1 }: MenuColumnProps) {
  return (
    <Component>
      <Title>{title}</Title>

      <Body columns={columns}>
        {menu.map((item, index) => (
          <MenuItem key={index}>
            <MenuLink
              to={item.link}
              target={item.isNewTab ? '_blank' : '_self'}
            >
              {item.icon ? (
                <Icon>{item.icon}</Icon>
              ) : item.image ? (
                <Image
                  loading="lazy"
                  {...convertThumbnailToPictureProps(item.image)}
                  alt={item.title}
                />
              ) : null}

              <MenuTitle>{item.title}</MenuTitle>
            </MenuLink>
          </MenuItem>
        ))}
      </Body>
    </Component>
  );
}

export default MenuColumn;

const Component = styled.div``;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue200};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 16px;
  `)}
`;

const Body = styled.ul<{ columns: number }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  gap: 20px;
  margin-top: 20px;
`;

const MenuItem = styled.li`
  display: flex;
`;

const Icon = styled.div`
  margin-right: 10px;

  svg {
    width: 40px;
    height: 22px;

    path {
      fill: ${(props) => props.theme.gray1900};
    }

    circle {
      stroke: ${(props) => props.theme.gray1900};
    }

    path,
    circle {
      transition: 0.3s;
    }
  }
`;

const MenuTitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid transparent;
  transition: 0.3s;
  color: ${(props) => props.theme.black};
`;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;

  ${media.tablet(
    css`
      flex: 1;
    `
  )}

  &:hover {
    ${Icon} {
      svg {
        path {
          fill: ${(props) => props.theme.main};
        }

        circle {
          stroke: ${(props) => props.theme.main};
        }
      }
    }

    ${MenuTitle} {
      color: ${(props) => props.theme.main};
      border-color: ${(props) => props.theme.main};
    }
  }
`;

const Image = styled(Picture)`
  border-radius: 10px;
  padding: 2px;
  margin-right: 8px;
  background: ${colors.white};

  picture,
  img {
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }

  img {
    object-fit: cover;
  }
`;
