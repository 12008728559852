import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { media } from '@/utils/mixin';
import {
  fetchMainMenuBannersThunk,
  selectMainMenuBanners,
} from '@/store/reducers/banners';
import { useMobileMenuCloseOnRouteChange } from '@/layout/hooks';

import Logo from './components/Logo';
import Location from './components/Location';
import MobilePhone from './components/MobilePhone';
import MobileMenu from './components/MobileMenu';
import Menu from './components/Menu';
import Phone from './components/Phone';
import { User } from './components/User';
import { MobileUser } from './components/User';

interface Props {
  className?: string;
}

function Header({ className }: Props) {
  const dispatch = useTypedDispatch();

  const { mainMenuStatus } = useTypedSelector(selectMainMenuBanners);
  const isMobile = useMedia('(max-width: 1259px)');

  useEffect(() => {
    if (mainMenuStatus === 'IDLE') {
      try {
        (async () => {
          await dispatch(fetchMainMenuBannersThunk());
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useMobileMenuCloseOnRouteChange();

  return (
    <Component className={className}>
      <Container>
        {isMobile ? (
          <>
            <Logo />
            <Right>
              <Location isMobile={isMobile} />
              <MobileUser />
              <MobilePhone />
              <MobileMenu />
            </Right>
          </>
        ) : (
          <>
            <Logo />
            <Menu />
            <Location />
            <Phone />
            <User />
          </>
        )}
      </Container>
    </Component>
  );
}

export default Header;

export const Component = styled.header`
  z-index: 10000;
  position: relative;
  height: 80px;
  border-bottom: 1px solid ${(props) => props.theme.blue_1};
  background: ${(props) => props.theme.blue};

  ${media.tablet(css`
    position: sticky;
    top: 0;
  `)}

  ${media.mobile(css`
    height: 60px;
  `)}
`;

const Container = styled.div`
  padding: 0 40px;
  height: 80px;
  display: flex;
  align-items: center;

  ${media.tablet(css`
    justify-content: space-between;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    height: 60px;
  `)}
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;
