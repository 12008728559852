import React from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';

import { useTypedSelector } from '@/store/store';
import useSettingItem from '@/hooks/useSettingItem';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import Link from '@/components/Link';
import { getPhoneLink } from '@/utils/common';
import CallbackForm from '@/components/modals/CallbackForm';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { googleEvent } from '@/utils/events';
import { usePhoneNumber } from '@/hooks/usePhoneNumber';
import { HeaderVariables } from '@/layout/components/Header/constants';
import { handleSubmitCallbackForm } from '@/utils/forms';

function Info() {
  const menuItems =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, 'header_mobile')
    ) ?? [];

  const openModal = useModal();
  const phoneCallbackGoogleEvent = useSettingItem(
    'PHONE_CALLBACK_GOOGLE_EVENT'
  );
  const phoneClickGoogleEvent = useSettingItem('PHONE_CLICK_GOOGLE_EVENT');

  const onPhoneClick = () => {
    if (phoneClickGoogleEvent) {
      googleEvent(phoneClickGoogleEvent);
    }
  };

  function handleCallClick(event: React.MouseEvent) {
    event.preventDefault();

    openModal(
      CallbackForm,
      {
        request: (values) =>
          handleSubmitCallbackForm(values, {
            type: phoneCallbackGoogleEvent ?? '',
          }),
        title: 'Заказать обратный звонок',
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  }

  const phoneNumber = usePhoneNumber();
  const phoneNumberLink = getPhoneLink(phoneNumber);

  return (
    <Component>
      <Menu>
        {menuItems.map((menuItem, index) => (
          <React.Fragment key={index}>
            <MenuLabelLink
              to={menuItem.link}
              target={menuItem.isNewTab ? '_blank' : '_self'}
            >
              {menuItem.label}
            </MenuLabelLink>
            <MenuList>
              {menuItem.children.map((menuChildItem, index) => {
                let label = menuChildItem.label;
                let link = menuChildItem.link;

                if (menuChildItem.label === HeaderVariables.Phone) {
                  label = phoneNumber ?? '';
                  link = phoneNumberLink;
                }

                return (
                  <MenuItem key={index}>
                    {link === HeaderVariables.Call ? (
                      <button
                        type="button"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCallClick(event);
                        }}
                      >
                        {label}
                      </button>
                    ) : (
                      <Link
                        to={link}
                        target={menuChildItem.isNewTab ? '_blank' : '_self'}
                        onClick={() =>
                          link && link.startsWith('tel:+') && onPhoneClick()
                        }
                      >
                        {label}
                      </Link>
                    )}
                  </MenuItem>
                );
              })}
            </MenuList>
          </React.Fragment>
        ))}
      </Menu>
    </Component>
  );
}

export default Info;

const Component = styled.div`
  padding: 25px 20px;
`;

const Menu = styled.div``;

const MenuLabelLink = styled(Link)`
  display: flex;
  flex: 1;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: ${(props) => props.theme.black200};
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  display: flex;
  font-size: 14px;
  line-height: 20px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  button,
  a {
    flex: 1;
    text-align: left;
    color: ${(props) => props.theme.main};
  }
`;
