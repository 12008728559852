import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';

import { MenuProps } from './types';

const Menu: React.VFC<MenuProps> = ({ items }) => {
  return (
    <Component>
      {items.map(({ label, link, isNewTab }, index) => (
        <Item key={index}>
          <Link to={link} target={isNewTab ? '_blank' : '_self'}>
            {label}
          </Link>
        </Item>
      ))}
    </Component>
  );
};

export default Menu;

const Component = styled.ul``;

const Item = styled.li`
  display: flex;

  &:not(:first-child) {
    margin-top: 16px;
  }

  a {
    flex: 1;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${(props) => props.theme.black200};
  }
`;
