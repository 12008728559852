import { MenuItemType } from '@tager/web-modules';

import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';

export function useDealerWebFooterMenu(): {
  withChildrens: MenuItemType[];
  withoutChildrens: MenuItemType[];
  firstInOrder: 'withChildrens' | 'withoutChildrens';
} {
  const footerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  return footerMenu.reduce<{
    withChildrens: MenuItemType[];
    withoutChildrens: MenuItemType[];
    firstInOrder: 'withChildrens' | 'withoutChildrens';
  }>(
    (acc, currentValue, currentIndex) => {
      if (currentValue.children && currentValue.children.length > 0) {
        return {
          withChildrens: [...acc.withChildrens, currentValue],
          withoutChildrens: [...acc.withoutChildrens],
          firstInOrder:
            currentIndex === 0 ? 'withChildrens' : 'withoutChildrens',
        };
      } else {
        return {
          withChildrens: [...acc.withChildrens],
          withoutChildrens: [...acc.withoutChildrens, currentValue],
          firstInOrder:
            currentIndex === 0 ? 'withoutChildrens' : 'withChildrens',
        };
      }
    },
    { withChildrens: [], withoutChildrens: [], firstInOrder: 'withChildrens' }
  );
}
