import React from 'react';
import styled, { css } from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { componentColors } from '@/layout/components/DealerWebFooter/constants';
import { media } from '@/utils/mixin';

function FooterCopyright() {
  const copyright = useSettingItem('FOOTER_DEALER_WEB_COPYRIGHT');

  return <Component dangerouslySetInnerHTML={{ __html: copyright ?? '' }} />;
}

export default FooterCopyright;

const Component = styled.div`
  flex: 1 1 400px;
  display: flex;
  justify-content: right;
  font-size: 14px;
  line-height: 14px;
  color: ${componentColors.footerBottomText};

  ${media.tablet(css`
    justify-content: left;
    flex: auto;
    margin-top: 20px;
  `)}
`;
