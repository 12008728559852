import React from 'react';
import styled, { css } from 'styled-components';

import FlagIcon from '@/assets/svg/flag.svg';
import Link from '@/components/Link';
import { useTypedSelector } from '@/store/store';
import { getCabinetPersonalData } from '@/store/reducers/cabinet';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function Promo() {
  const profileInformation = useTypedSelector(getCabinetPersonalData);

  return (
    <Component>
      <ComponentContainer>
        <Icon>
          <FlagIcon />
        </Icon>
        <Body>
          <Text>
            Ваш профиль заполнен на{' '}
            <IDC>{profileInformation?.profilePercentFullness ?? 0}%</IDC>
          </Text>
          <StyledLink to={'/personal'}>Заполнить</StyledLink>
        </Body>
      </ComponentContainer>
    </Component>
  );
}

export default Promo;

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  width: 50%;
  padding: 0 15px;
  margin-bottom: 30px;

  ${media.tablet(css`
    width: 100%;
    padding: 0;
  `)}
`;

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 28px;
  border-top: 1px solid ${colors.gray900};

  ${media.tablet(css`
    flex-direction: row-reverse;
  `)}
`;

const Icon = styled.div`
  width: 36px;
  margin-right: 12px;

  ${media.tablet(css`
    margin-left: 12px;
  `)}
`;

const Body = styled.div`
  flex: 1;
  color: ${colors.blue600};
  font-size: 14px;
  line-height: 20px;
`;

const Text = styled.div`
  color: ${(props) => props.theme.blue100};
  font-size: 14px;
  line-height: 20px;
`;

const IDC = styled.span`
  color: ${colors.red100};
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 8px;
  color: ${colors.gray500};
  text-transform: uppercase;

  font-size: 14px;
  line-height: 20px;

  border-bottom: 1px solid transparent;
  transition: color 0.15s, border 0.15s;

  &:hover {
    color: ${colors.gray500};
    border-bottom: 1px solid ${colors.gray500};
  }
`;
