import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { isNotNullish } from '@tager/web-core';

import CloseIcon from '@/assets/svg/close-24dp.svg';
import AccountIcon from '@/assets/svg/account.svg';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { getIsUserAuthorized } from '@/store/reducers/auth';
import {
  selectMobileUserOpened,
  setMobileUserOpened,
} from '@/store/reducers/layout';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import {
  getCabinetPersonalData,
  getCabinetProfile,
} from '@/store/reducers/cabinet';

import Slider from './components/Slider';
import NoCars from './components/NoCars';
import Logout from './components/Logout';
import Promo from './components/Promo';
import Actions from './components/Actions';
import Menu from './components/Menu';

function MobileUser() {
  const dispatch = useTypedDispatch();
  const isUserAuthorized = useTypedSelector(getIsUserAuthorized);
  const profile = useTypedSelector(getCabinetProfile);
  const personalData = useTypedSelector(getCabinetPersonalData);
  const isOpen = useTypedSelector(selectMobileUserOpened);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const slides = profile?.cars && profile.cars.length > 0 ? profile.cars : [];
  const isUserCars = slides.length > 0;

  return (
    <Component>
      {isUserAuthorized ? (
        <Button
          type="button"
          onClick={() => dispatch(setMobileUserOpened(!isOpen))}
        >
          <AccountIcon />
        </Button>
      ) : (
        <ButtonLink href={'/personal/login'}>
          <AccountIcon />
        </ButtonLink>
      )}
      <Panel isOpen={isOpen}>
        <Name>
          {personalData?.name} {personalData?.lastName}
        </Name>
        <CloseButton
          type="button"
          onClick={() => dispatch(setMobileUserOpened(!isOpen))}
        >
          <CloseIcon />
        </CloseButton>
      </Panel>
      <DropDownMenu isOpen={isOpen}>
        <DropDownMenuContainer>
          {isUserCars ? (
            <Slider slides={slides} />
          ) : profile?.availability?.cars ? (
            <NoCars infoType="archived" />
          ) : (
            <NoCars infoType="noCars" />
          )}
          <Actions />
          <Promo />

          <Menu
            items={[
              {
                label: 'Личные данные',
                url: '/personal',
              },
            ]}
          />

          <Menu
            items={[
              profile?.availability?.cars
                ? {
                    label: 'Мои автомобили',
                    url: '/personal/cars',
                  }
                : undefined,
              profile?.availability?.purchasesCars ||
              profile?.availability?.purchasesOther ||
              profile?.availability?.purchasesInsurances ||
              profile?.availability?.purchasesServices
                ? {
                    label: 'Мои покупки',
                    url: '/personal/purchases',
                  }
                : undefined,
              profile?.availability?.requests
                ? {
                    label: 'Мои заявки',
                    url: '/personal/requests',
                  }
                : undefined,
              profile?.availability?.bonuses
                ? {
                    label: 'Мои бонусы',
                    url: '/personal/bonuses',
                  }
                : undefined,
            ].filter(isNotNullish)}
          />

          <Logout />
        </DropDownMenuContainer>
      </DropDownMenu>
    </Component>
  );
}

export default MobileUser;

const Component = styled.div`
  margin: 0 5px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 40px;
  height: 40px;

  ${media.mobileSmall(css`
    width: 25px;
    height: 25px;
  `)}

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  width: 25px;
  height: 25px;

  @media (min-width: 376px) {
    width: 40px;
    height: 40px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Panel = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 60px;

  padding: 0 20px;

  background: ${(props) => props.theme.blue};

  opacity: 0;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

const Name = styled.span`
  margin-right: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 16px;
  line-height: 24px;

  color: ${colors.white};
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  color: ${colors.white};

  svg {
    width: 28px;
    height: 28px;
    fill: currentColor;
  }
`;

const DropDownMenu = styled.div<{ isOpen: boolean }>`
  display: none;

  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 300;

  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 60px);

  overflow-x: hidden;
  overflow-y: scroll;

  overscroll-behavior: contain;

  background: ${(props) => props.theme.white};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}
`;

const DropDownMenuContainer = styled.div`
  padding: 20px;
`;
