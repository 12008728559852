import { isAndroid, isIOS } from 'react-device-detect';

import { Nullable } from '@tager/web-core';

import useSettingItem from '@/hooks/useSettingItem';

export const useLink = (): Nullable<string> => {
  const appStoreAppLink = useSettingItem('FOOTER_MOBILE_APPSTORE_URL');
  const googlePlayAppLink = useSettingItem('FOOTER_MOBILE_GOOGLE_PLAY_URL');

  if (isAndroid) {
    return googlePlayAppLink;
  }

  if (isIOS) {
    return appStoreAppLink;
  }

  return null;
};
