import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { useHover } from '@/hooks/useHover';

import { DropdownProps } from './types';

function Dropdown({ label, link, isNewTab, children }: DropdownProps) {
  const { isHovering, getHoverProps } = useHover({
    mouseLeaveDelay: 300,
  });

  return (
    <Component {...getHoverProps()}>
      {link ? (
        <NavLink
          to={link}
          target={isNewTab ? '_blank' : '_self'}
          isOpen={isHovering}
        >
          {label}
        </NavLink>
      ) : (
        <NavButton isOpen={isHovering}>{label}</NavButton>
      )}
      <DropdownMenu isOpen={isHovering}>
        <DropdownMenuContainer>{children}</DropdownMenuContainer>
      </DropdownMenu>
    </Component>
  );
}

export default Dropdown;

const Component = styled.div``;

const NavStyles = css<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 21px 25px;
  border: none;
  color: ${colors.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.15s;
  white-space: nowrap;
  flex-wrap: nowrap;
  z-index: 91;

  &:hover {
    background: ${(props) => props.theme.white};
    color: ${(props) => props.theme.main};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${(props) => props.theme.white};
      color: ${(props) => props.theme.main};
    `};
`;

const NavLink = styled(Link)`
  ${NavStyles}
`;

const NavButton = styled.button`
  ${NavStyles}
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  display: none;

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 201;

  padding: 40px 0 30px;
  box-shadow: ${(props) => props.theme.blackShadow300};
  background: ${(props) => props.theme.white};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`;

const DropdownMenuContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
`;
