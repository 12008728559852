import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { ModelsProps } from './types';
import ModelCard from './components/ModelCard';

function Models({ models }: ModelsProps) {
  if (!models || models.length === 0) {
    return null;
  }

  return (
    <Component>
      {models.map((item, index) => (
        <ModelCard key={index} {...item} />
      ))}
    </Component>
  );
}

export default Models;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
