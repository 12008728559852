import React from 'react';
import styled, { css } from 'styled-components';

import LogoImage from '@/assets/svg/atlant-logo.svg';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

function Logo() {
  return (
    <Component>
      <StyledLink to="/">
        <StyledLogoImage />
      </StyledLink>
    </Component>
  );
}

export default Logo;

const Component = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;
  margin-right: 25px;
  position: relative;

  ${media.tablet(css`
    margin-right: 15px;
  `)}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const StyledLogoImage = styled(LogoImage)`
  width: 190px;
  height: 50px;

  ${media.mobile(css`
    width: 114px;
    height: 32px;
  `)}
`;
