import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { componentColors } from '@/layout/components/DealerWebFooter/constants';

import { MenuItemProps } from './types';
import ChildrenLink from './ChildrenLink';

function MenuItem({ label, link, isNewTab, children, onClick }: MenuItemProps) {
  return (
    <Component>
      <TitleLink
        to={link}
        target={`${isNewTab ? '_blank' : '_self'}`}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
          onClick?.(link, event)
        }
      >
        {label}
      </TitleLink>

      {children && children.length > 0 && (
        <Children>
          {children.map((item, index) => (
            <ChildrenLink key={index} onClick={onClick} {...item} />
          ))}
        </Children>
      )}
    </Component>
  );
}

export default MenuItem;

const Component = styled.div``;

const TitleLink = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: ${componentColors.footerNavHeader};
  transition: 0.3s;

  &:hover {
    color: ${componentColors.footerNavHeaderHover};
  }
`;

const Children = styled.div``;
