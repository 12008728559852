import { useRouter } from 'next/router';

import useSettingItem from '@/hooks/useSettingItem';
import {
  getServiceSingleLanding,
  getServiceBrandLanding,
  getServiceBrandServiceLanding,
} from '@/store/reducers/service';
import useLayoutPage from '@/hooks/useLayoutPage';
import { useTypedSelector } from '@/store/store';
import { usePageType } from '@/hooks/usePageType';

export function usePhoneNumber() {
  const currentPage = useLayoutPage();
  const routePath = useRouter().asPath;
  const { pageType } = usePageType();
  const phoneCommon = useSettingItem('PHONE_COMMON');
  const phoneAmp = useSettingItem('PHONE_AMP');
  const phoneLkk = useSettingItem('PHONE_LKK');
  const serviceSingleData = useTypedSelector(getServiceSingleLanding);
  const serviceBrandLandingData = useTypedSelector(getServiceBrandLanding);
  const serviceBrandServiceLandingData = useTypedSelector(
    getServiceBrandServiceLanding
  );

  const templatePhone = currentPage?.templateFields?.phone;

  if (templatePhone) {
    return templatePhone;
  }

  // Service - Single Service
  if (
    pageType === 'SERVICE_SINGLE_PAGE' &&
    serviceSingleData &&
    serviceSingleData.phone
  ) {
    return serviceSingleData.phone;
  }

  // Service - Single Brand
  if (
    pageType === 'SERVICE_BRAND_PAGE' &&
    serviceBrandLandingData &&
    serviceBrandLandingData.phone
  ) {
    return serviceBrandLandingData.phone;
  }

  // Service - Single Service Brand
  if (
    pageType === 'SERVICE_BRAND_SERVICE_PAGE' &&
    serviceBrandServiceLandingData &&
    serviceBrandServiceLandingData.phone
  ) {
    return serviceBrandServiceLandingData.phone;
  }

  switch (true) {
    case routePath.startsWith('/cars/amp'):
      return phoneAmp;
    case routePath.startsWith('/personal'):
      return phoneLkk;
    default:
      return phoneCommon;
  }
}
