import React, { ReactNode } from 'react';

import Hatchback from '@/assets/svg/car/body/hatchback.svg';
import Sedan from '@/assets/svg/car/body/sedan.svg';
import Wagon from '@/assets/svg/car/body/wagon.svg';
import Coupe from '@/assets/svg/car/body/coupe.svg';
import Minivan from '@/assets/svg/car/body/minivan.svg';
import Van from '@/assets/svg/car/body/van.svg';
import Pickup from '@/assets/svg/car/body/pickup.svg';
import Suv from '@/assets/svg/car/body/suv.svg';
import Crossover from '@/assets/svg/car/body/crossover.svg';
import Minibus from '@/assets/svg/car/body/minibus.svg';
import Liftback from '@/assets/svg/car/body/liftback.svg';
import Chassis from '@/assets/svg/car/body/chassis.svg';
import Cabriolet from '@/assets/svg/car/body/cabriolet.svg';
import ShootingBrake from '@/assets/svg/car/body/shooting-brake.svg';

export const bodyIconMap: Record<string, ReactNode> = {
  Хэтчбек: <Hatchback />,
  Седан: <Sedan />,
  Универсал: <Wagon />,
  Купе: <Coupe />,
  Минивэн: <Minivan />,
  Фургон: <Van />,
  Пикап: <Pickup />,
  Внедорожник: <Suv />,
  Кроссовер: <Crossover />,
  Микроавтобус: <Minibus />,
  Лифтбек: <Liftback />,
  Шасси: <Chassis />,
  Автобус: <Minibus />,
  Кабриолет: <Cabriolet />,
  'Шутинг-брейк': <ShootingBrake />,
};
