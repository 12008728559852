import React from 'react';
import styled, { css } from 'styled-components';

import B2bSvg from '@/assets/svg/dealer-web/b2b-logo.svg';
import B2bAtlantSvg from '@/assets/svg/dealer-web/atlant-logo-b2b.svg';
import HisunSvg from '@/assets/svg/dealer-web/hisun-logo.svg';
import HisunAtlantSvg from '@/assets/svg/atlant-logo.svg';
import JacSvg from '@/assets/svg/dealer-web/jac-logo.svg';
import JacAtlantSvg from '@/assets/svg/dealer-web/atlant-logo-jac.svg';
import SollersSvg from '@/assets/svg/dealer-web/sollers-logo.svg';
import SollersAtlantSvg from '@/assets/svg/dealer-web/atlant-logo-sollers.svg';
import JetourSvg from '@/assets/svg/dealer-web/jetour-logo.svg';
import JetourAtlantSvg from '@/assets/svg/dealer-web/atlant-logo-jetour.svg';
import ZeekrSvg from '@/assets/svg/dealer-web/zeekr-logo.svg';
import ZeekrAtlantSvg from '@/assets/svg/dealer-web/atlant-logo-zeekr.svg';
import { media } from '@/utils/mixin';
import { getTheme } from '@/constants/theme';
import { getWebsiteSource } from '@/utils/common';
import { componentColors } from '@/layout/components/DealerWebHeader/constants';

function CollaborateLogos() {
  const theme = getTheme();
  const websiteSource = getWebsiteSource();

  switch (theme) {
    case 'b2b':
      return (
        <React.Fragment>
          <BrandLogo>
            <B2bSvg />
          </BrandLogo>
          <DealerLogo>
            <B2bAtlantSvg />
          </DealerLogo>
        </React.Fragment>
      );

    case 'hisun':
      return (
        <React.Fragment>
          <BrandLogo>
            <HisunSvg />
          </BrandLogo>
          <DealerLogo>
            <HisunAtlantSvg />
          </DealerLogo>
        </React.Fragment>
      );

    case 'jac':
      return (
        <React.Fragment>
          <BrandLogo>
            <JacSvg />
          </BrandLogo>
          <DealerLogo>
            <JacAtlantSvg />
          </DealerLogo>
        </React.Fragment>
      );

    case 'sollers':
      return (
        <React.Fragment>
          <BrandLogo>
            <SollersSvg />
          </BrandLogo>
          <DealerLogo>
            <SollersAtlantSvg />
          </DealerLogo>
        </React.Fragment>
      );

    case 'jetour':
      const isDealerLogoVisible = websiteSource === 'JETOUR';

      return (
        <React.Fragment>
          <BrandLogo>
            <JetourSvg />
          </BrandLogo>
          {isDealerLogoVisible && (
            <DealerLogo>
              <JetourAtlantSvg />
            </DealerLogo>
          )}
        </React.Fragment>
      );

    case 'zeekr':
      return (
        <React.Fragment>
          <BrandLogo>
            <ZeekrSvg />
          </BrandLogo>
          <DealerLogo>
            <ZeekrAtlantSvg />
          </DealerLogo>
        </React.Fragment>
      );

    default:
      return <React.Fragment />;
  }
}

export default CollaborateLogos;

const BrandLogo = styled.div`
  svg {
    width: 156px;
    height: 45px;
  }

  ${media.tablet(css`
    svg {
      width: 107px;
      height: 31px;
    }
  `)}
`;

const DealerLogo = styled.div`
  padding-left: 14px;
  margin-left: 14px;
  border-left: 1px solid ${componentColors.headerLine};

  svg {
    width: 127px;
    height: 29px;
  }

  ${media.tablet(css`
    padding-left: 10px;
    margin-left: 10px;

    svg {
      width: 88px;
      height: 20px;
    }
  `)}
`;
