import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';

import { cookie, useIsomorphicLayoutEffect } from '@tager/web-core';

import { OptionType } from '@/typings/common';
import NavigationIcon from '@/assets/svg/navigation.svg';
import ArrowIcon from '@/assets/svg/chevron-down.svg';
import { colors } from '@/constants/theme';
import { getCityFromCookie } from '@/utils/cookies';

import { locationData } from '../../constants';

function LocationDesktop() {
  const [selectedCity, setSelectedCity] = useState<OptionType>(locationData[4]);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
  } = useSelect({
    selectedItem: selectedCity,
    items: locationData,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setSelectedCity(selectedItem);
        cookie.set('selectedCity', JSON.stringify(selectedItem));
      }
    },
  });

  useIsomorphicLayoutEffect(() => {
    const selectedLocationFromCookie = getCityFromCookie();

    if (selectedLocationFromCookie) {
      setSelectedCity(selectedLocationFromCookie);
    }
  }, []);

  return (
    <Component>
      <Select type="button" {...getToggleButtonProps()}>
        <StyledNavigationIcon />
        <Label>{selectedItem?.label}</Label>
        <StyledArrowIcon isOpen={isOpen} />
      </Select>

      <Content isOpen={isOpen} {...getMenuProps()}>
        {locationData.map((item, index) => {
          const isActive = item.value === selectedItem?.value;

          return (
            <ListItem
              key={index}
              isActive={isActive}
              {...getItemProps({
                item: item,
                index,
              })}
            >
              {item.label}
            </ListItem>
          );
        })}
      </Content>
    </Component>
  );
}

export default LocationDesktop;

const Component = styled.div`
  position: relative;
  width: 90px;
`;

const Select = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 16px;
`;

const StyledNavigationIcon = styled(NavigationIcon)`
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
  color: ${colors.white};
`;

const StyledArrowIcon = styled(ArrowIcon)<{ isOpen: boolean }>`
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  transition: transform 0.15s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

const Content = styled.div<{ isOpen: boolean }>`
  z-index: 5;
  pointer-events: none;
  position: absolute;
  top: 25px;
  width: 100%;
  max-height: 0;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.blackShadow100};
  opacity: 0;
  transition: all 0.3s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: auto;
      opacity: 1;
      max-height: 200px;
      transition: all 0.3s ease-in-out;
    `}
`;

const ListItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 7px 10px;
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.black200};

  &:hover {
    color: ${(props) => props.theme.main};
    background: ${(props) => props.theme.white_1};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      display: none;
    `}
`;
