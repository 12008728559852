import React from 'react';
import styled, { css } from 'styled-components';

import { componentColors } from '@/layout/components/DealerWebHeader/constants';
import Link from '@/components/Link';
import { useHover } from '@/hooks/useHover';

import { DropdownProps } from './types';

function Dropdown({ label, link, isNewTab, onClick, children }: DropdownProps) {
  const { isHovering, getHoverProps } = useHover({
    mouseLeaveDelay: 300,
  });

  return (
    <Component {...getHoverProps()}>
      <StyledLink
        to={link}
        target={isNewTab ? '_blank' : '_self'}
        onClick={(event) => onClick?.(link, event)}
      >
        {label}
      </StyledLink>
      <Content isOpen={isHovering}>
        <ContentContainer>{children}</ContentContainer>
      </Content>
    </Component>
  );
}

export default Dropdown;

const Component = styled.div``;

const StyledLink = styled(Link)`
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  color: ${componentColors.headerNav};

  &:hover {
    color: ${componentColors.headerNavHover};
  }
`;

const Content = styled.div<{ isOpen: boolean }>`
  z-index: 10000;
  display: none;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  padding: 40px 0;
  background: ${componentColors.headerBackground};
  box-shadow: ${(props) => props.theme.blackShadow300};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`;

const ContentContainer = styled.div`
  max-width: 900px;
  padding: 0 40px;
  margin: 0 auto;
`;
