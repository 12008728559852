import React from 'react';
import styled, { css } from 'styled-components';

import { useHover } from '@/hooks/useHover';
import ArrowIcon from '@/assets/svg/chevron-down.svg';
import { componentColors } from '@/layout/components/DealerWebHeader/constants';
import Link from '@/components/Link';

import { OtherDropdownProps } from './types';

function OtherDropdown({ menu, onClick }: OtherDropdownProps) {
  const { isHovering, getHoverProps } = useHover({
    mouseLeaveDelay: 300,
  });

  return (
    <Component {...getHoverProps()}>
      <MenuButton isOpen={isHovering}>
        Еще <ArrowIcon />
      </MenuButton>

      <Content isOpen={isHovering}>
        {menu.map((item, index) => (
          <MenuItem
            key={index}
            to={item.link ?? ''}
            target={item.isNewTab ? '_blank' : '_self'}
            onClick={(event) => onClick?.(item.link, event)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Content>
    </Component>
  );
}

export default OtherDropdown;

const Component = styled.div`
  position: relative;
`;

const MenuItemStyles = css`
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  transition: 0.3s;
  color: ${componentColors.headerNav};

  &:hover {
    color: ${componentColors.headerNavHover};
  }
`;

const MenuButton = styled.button<{ isOpen: boolean }>`
  ${MenuItemStyles};

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    transition: 0.3s;

    path {
      transition: 0.3s;
      stroke: ${componentColors.headerNav};
    }
  }

  &:hover {
    svg {
      path {
        stroke: ${componentColors.headerNavHover};
      }
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};
`;

const Content = styled.div<{ isOpen: boolean }>`
  z-index: 10000;
  pointer-events: none;
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  padding: 20px;
  max-width: 400px;
  flex-direction: column;
  background: ${componentColors.headerBackground};
  box-shadow: ${(props) => props.theme.blackShadow300};

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: auto;
      display: flex;
      opacity: 1;
    `};
`;

const MenuItem = styled(Link)`
  ${MenuItemStyles};

  &:not(:first-child) {
    margin-top: 16px;
  }
`;
