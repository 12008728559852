import React, { useEffect, useState } from 'react';

import { isBrowser, notFalsy } from '@tager/web-core';

import { getPreloaderLogos, isPreloaderEnabled } from './Preloader.helpers';
import * as S from './Preloader.style';

type PreloaderStatus = 'VISIBLE' | 'FADING_OUT' | 'HIDDEN';

type Props = {
  hidden?: boolean;
  className?: string;
};

function Preloader({ hidden, className }: Props) {
  function isInitiallyVisible(): boolean {
    const isVisible = isBrowser()
      ? isPreloaderEnabled() && !window.isPreloaderHidden
      : isPreloaderEnabled();

    return !hidden ? isVisible : false;
  }

  const [status, setStatus] = useState<PreloaderStatus>(
    isInitiallyVisible() ? 'VISIBLE' : 'HIDDEN'
  );

  function hidePreloader() {
    if (window.isPreloaderHidden) {
      return;
    }

    setStatus('FADING_OUT');
    window.isPreloaderHidden = true;
    document.body.classList.add('loaded');
  }

  useEffect(() => {
    if (status !== 'VISIBLE') {
      return;
    }

    const timeout = setTimeout(() => {
      hidePreloader();
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [status]);

  useEffect(() => {
    if (status !== 'VISIBLE') {
      return;
    }

    if (document.readyState !== 'loading') {
      hidePreloader();
    }
  }, [status]);

  if (status === 'HIDDEN') {
    return null;
  }

  const containerClassName = [
    status === 'FADING_OUT' ? 'fade-out' : undefined,
    className,
  ]
    .filter(notFalsy)
    .join(' ');

  return (
    <S.Overlay
      className={containerClassName}
      onAnimationEnd={() => setStatus('HIDDEN')}
      data-preloader-overlay
    >
      <S.Inner>{getPreloaderLogos()}</S.Inner>
    </S.Overlay>
  );
}

export default Preloader;
