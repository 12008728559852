import React from 'react';
import styled, { css } from 'styled-components';

import { generateNumberArray } from '@tager/web-core';

import { colors } from '@/constants/theme';

import { RatingProps } from './types';
import { calculateFinalPercent } from './helpers';

function Rating({ className, count, value }: RatingProps) {
  const stars = generateNumberArray(count);
  const percentFilled = Math.round((value / count) * 100);

  return (
    <Component className={className}>
      <StarsList>
        {stars.map((index) => (
          <Star key={index}>&#9733;</Star>
        ))}
      </StarsList>
      <StarsList filled value={calculateFinalPercent(percentFilled)}>
        {stars.map((index) => (
          <Star key={index} filled>
            &#9733;
          </Star>
        ))}
      </StarsList>
    </Component>
  );
}

export default Rating;

const Component = styled.div`
  position: relative;
`;

const StarsList = styled.div<{ filled?: boolean; value?: number }>`
  display: flex;

  ${({ filled, value }) =>
    filled && value
      ? css`
          z-index: 1;
          position: absolute;
          top: 0;
          clip-path: polygon(0 0, ${value}% 0, ${value}% 100%, 0% 100%);
        `
      : filled && !value
      ? css`
          display: none;
        `
      : ``}
`;

const Star = styled.div<{ filled?: boolean }>`
  font-size: 16px;
  line-height: 16px;
  color: ${colors.gray400};

  ${({ filled }) =>
    filled &&
    css`
      color: #f5a623;
    `}
`;
