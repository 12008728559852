import React from 'react';
import styled from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import FacebookIcon from '@/assets/svg/socials-footer/facebook.svg';
import YoutubeIcon from '@/assets/svg/socials-footer/youtube.svg';
import OdnoklassnikiIcon from '@/assets/svg/socials-footer/odnoklassniki.svg';
import TwitterIcon from '@/assets/svg/socials-footer/twitter.svg';
import InstagramIcon from '@/assets/svg/socials-footer/instagram.svg';
import VkIcon from '@/assets/svg/socials-footer/vk.svg';
import TelegramIcon from '@/assets/svg/socials-footer/telegram.svg';

function Socials() {
  const facebookUrl = useSettingItem('FOOTER_SOCIAL_FACEBOOK');
  const youtubeUrl = useSettingItem('FOOTER_SOCIAL_YOUTUBE');
  const odnoklassnikiUrl = useSettingItem('FOOTER_SOCIAL_ODNOKLASSNIKI');
  const twitterUrl = useSettingItem('FOOTER_SOCIAL_TWITTER');
  const instagramUrl = useSettingItem('FOOTER_SOCIAL_INSTAGRAM');
  const vkontakteUrl = useSettingItem('FOOTER_SOCIAL_VKONTAKTE');
  const telegramUrl = useSettingItem('FOOTER_SOCIAL_TELEGRAM');

  return (
    <Component>
      {facebookUrl ? (
        <Item>
          <Link href={facebookUrl} target="_blank" title="Facebook">
            <FacebookIcon />
          </Link>
        </Item>
      ) : null}

      {youtubeUrl ? (
        <Item>
          <Link href={youtubeUrl} target="_blank" title="Youtube">
            <YoutubeIcon />
          </Link>
        </Item>
      ) : null}

      {odnoklassnikiUrl ? (
        <Item>
          <Link href={odnoklassnikiUrl} target="_blank" title="Одноклассники">
            <OdnoklassnikiIcon />
          </Link>
        </Item>
      ) : null}

      {twitterUrl ? (
        <Item>
          <Link href={twitterUrl} target="_blank" title="Twitter">
            <TwitterIcon />
          </Link>
        </Item>
      ) : null}

      {instagramUrl ? (
        <Item>
          <Link href={instagramUrl} target="_blank" title="Instagram">
            <InstagramIcon />
          </Link>
        </Item>
      ) : null}

      {vkontakteUrl ? (
        <Item>
          <Link href={vkontakteUrl} target="_blank" title="Vkontakte">
            <VkIcon />
          </Link>
        </Item>
      ) : null}

      {telegramUrl ? (
        <Item>
          <Link href={telegramUrl} target="_blank" title="Telegram">
            <TelegramIcon />
          </Link>
        </Item>
      ) : null}
    </Component>
  );
}

export default Socials;

const Component = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

const Link = styled.a`
  display: inline-block;

  &:hover {
    opacity: 0.6;
  }
`;
