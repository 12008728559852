import React from 'react';
import styled, { css } from 'styled-components';

import BYIcon from '@/assets/svg/by.svg';

interface Props {
  value: string;
  isLarge?: boolean;
}

function NumberPlate({ value, isLarge }: Props) {
  return (
    <Component isLarge={isLarge}>
      <Icon>
        <BYIcon />
      </Icon>
      <Text>{value}</Text>
    </Component>
  );
}

export default NumberPlate;

const Icon = styled.span`
  margin-right: 8px;
  max-width: 10px;

  svg {
    path:first-child {
      fill: ${(props) => props.theme.black200};
    }
  }
`;

const Text = styled.span``;

const Component = styled.div<{ isLarge?: boolean }>`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 2px 8px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.black200};
  color: ${(props) => props.theme.black200};
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;

  ${({ isLarge }) =>
    isLarge &&
    css`
      border-radius: 5px;
      padding: 0 5px;

      font-weight: 500;
      font-size: 24px;
      line-height: 32px;

      ${Icon} {
        max-width: 13px;
      }
    `}
`;
