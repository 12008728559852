import React from 'react';
import styled, { css } from 'styled-components';

import CallIcon from '@/assets/svg/call.svg';
import { usePhoneNumber } from '@/hooks/usePhoneNumber';
import { googleEvent } from '@/utils/events';
import useSettingItem from '@/hooks/useSettingItem';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';
import { colors } from '@/constants/theme';

function MobilePhone() {
  const phoneNumber = usePhoneNumber();
  const phoneNumberLink = getPhoneLink(phoneNumber);
  const phoneClickGoogleEvent = useSettingItem('PHONE_CLICK_GOOGLE_EVENT');

  const onPhoneClick = () => {
    if (phoneClickGoogleEvent) {
      googleEvent(phoneClickGoogleEvent);
    }
  };

  return (
    <Component>
      <Link onClick={onPhoneClick} href={phoneNumberLink}>
        <CallIcon />
      </Link>
    </Component>
  );
}

export default MobilePhone;

const Component = styled.div`
  margin: 0 5px;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 40px;
  height: 40px;
  color: ${colors.white};

  ${media.mobileSmall(css`
    width: 25px;
    height: 25px;
  `)}
`;
