import React from 'react';
import styled from 'styled-components';

import { usePhoneNumber } from '@/hooks/usePhoneNumber';
import { googleEvent } from '@/utils/events';
import useSettingItem from '@/hooks/useSettingItem';
import { getPhoneLink } from '@/utils/common';
import { colors } from '@/constants/theme';

function Phone() {
  const phoneNumber = usePhoneNumber();
  const phoneClickGoogleEvent = useSettingItem('PHONE_CLICK_GOOGLE_EVENT');

  const onPhoneClick = () => {
    if (phoneClickGoogleEvent) {
      googleEvent(phoneClickGoogleEvent);
    }
  };

  const onPhoneSubmit = () => {
    window.location.hash = 'cbh-open';
  };

  return (
    <Component>
      <Link onClick={onPhoneClick} href={getPhoneLink(phoneNumber)}>
        {phoneNumber}
      </Link>
      <Button onClick={onPhoneSubmit}>Заказать звонок</Button>
    </Component>
  );
}

export default Phone;

const Component = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled.button`
  color: ${colors.white};
  text-decoration: underline;
  font-weight: 700;
  margin-top: 5px;

  &:hover {
    text-decoration: none;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  color: ${colors.white};
`;
