import React from 'react';
import styled from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { getSubdomain } from '@/utils/common';

import Bottom from './components/Bottom';
import Main from './components/Main';

function FooterPurchase() {
  const disclaimerBySubdomains = useSettingItem(
    'FOOTER_CHECKOUT_DISCLAIMER_SUBDOMAINS'
  );
  const title = useSettingItem('FOOTER_CHECKOUT_TITLE');

  const footerText = useSettingItem('FOOTER_CHECKOUT_DISCLAIMER');

  const subdomainText = disclaimerBySubdomains.find(
    (item) => item.subdomain.toLowerCase() === getSubdomain()
  )?.text;

  return (
    <FooterContainer id="footer">
      <Main
        title={title ?? ''}
        text={subdomainText ?? footerText ?? ''}
        isPurchasePage
        menuAlias="footer_purchase"
      />
      <Bottom />
    </FooterContainer>
  );
}

export default FooterPurchase;

const FooterContainer = styled.footer``;
