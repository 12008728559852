import React from 'react';
import styled from 'styled-components';

import { componentColors } from '@/layout/components/DealerWebFooter/constants';
import Link from '@/components/Link';
import { getPhoneLink } from '@/utils/common';

import { ContactsProps } from './types';

function Contacts({ phones, emails, onPhoneClick }: ContactsProps) {
  if ((!phones || phones.length === 0) && (!emails || emails.length === 0)) {
    return null;
  }

  return (
    <Component>
      <Title>Контакты</Title>

      {phones && phones.length !== 0 && (
        <List>
          {phones.map((phone, index) => (
            <Contact
              key={index}
              to={getPhoneLink(phone)}
              onClick={onPhoneClick}
            >
              {phone}
            </Contact>
          ))}
        </List>
      )}

      {emails && emails.length !== 0 && (
        <List>
          {emails.map((email, index) => (
            <Contact key={index} to={`mailto:${email}`}>
              {email}
            </Contact>
          ))}
        </List>
      )}
    </Component>
  );
}

export default Contacts;

const Component = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: ${componentColors.footerNavHeader};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
`;

const Contact = styled(Link)`
  font-size: 14px;
  line-height: 14px;
  color: ${componentColors.footerNavChild};

  &:not(:first-child) {
    margin-top: 15px;
  }

  &:hover {
    color: ${componentColors.footerNavChildHover};
  }
`;
