import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { media } from '@/utils/mixin';

interface Props {
  items: Array<{ label: string; url: string }>;
}

function Menu({ items }: Props) {
  return (
    <Component>
      <List>
        {items.map((item, index) => (
          <Item key={index}>
            <StyledLink to={item.url}>{item.label}</StyledLink>
          </Item>
        ))}
      </List>
    </Component>
  );
}

export default Menu;

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  width: 16.6666666667%;
  padding: 0 15px;
  margin-bottom: 30px;

  ${media.tablet(css`
    width: 100%;
    padding: 0;
  `)}
`;

const List = styled.ul`
  width: 100%;
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.main};
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: color 0.15s, border 0.15s;

  &:hover {
    color: ${(props) => props.theme.main};
    text-decoration: none;
    border-bottom: 1px solid ${(props) => props.theme.main};
  }
`;
