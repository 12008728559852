import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { useTypedSelector } from '@/store/store';
import { selectMainMenuBanners } from '@/store/reducers/banners';
import { media } from '@/utils/mixin';

import { BannerProps } from './types';

function Banner({ label, link, isNewTab }: BannerProps) {
  const { mainMenuData } = useTypedSelector(selectMainMenuBanners);

  if (!mainMenuData || !mainMenuData.data || !mainMenuData.data.length) {
    return null;
  }

  return (
    <Component>
      <TitleLink to={link} target={isNewTab ? '_blank' : '_self'}>
        {label}
      </TitleLink>

      <BannerWrapper>
        <BannerLink
          to={mainMenuData.data[0].link}
          target={mainMenuData.data[0].openNewTab ? '_blank' : '_self'}
        />
        <BannerImage
          loading="lazy"
          {...convertThumbnailToPictureProps(
            mainMenuData.data[0].image.default
          )}
        />
      </BannerWrapper>
    </Component>
  );
}

export default Banner;

const Component = styled.div`
  width: 100%;
`;

const TitleLink = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.black200};
  border-bottom: 1px solid transparent;
  transition: color 0.15s, border 0.15s;

  ${media.tablet(
    css`
      display: flex;
      font-size: 14px;
      line-height: 20px;
    `
  )}

  &:hover {
    color: ${(props) => props.theme.main};
    border-bottom-color: ${(props) => props.theme.main};

    ${media.tablet(
      css`
        color: initial;
        border-bottom-color: transparent;
      `
    )}
  }
`;

const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
`;

const BannerLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const BannerImage = styled(Picture)`
  padding-top: 55%;

  img,
  picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
