import { getTheme } from '@/constants/theme';

const colorsB2b = {
  headerBackground: '#fff',
  headerNav: '#182028',
  headerNavHover: 'rgba(24, 32, 40, 0.6)',
  headerPhone: '#6C7075',
  headerPhoneHover: '#182028',
  headerPhoneButton: '#182028',
  headerPhoneButtonHover: '#0066b3',
  headerBurger: '#182028',
  headerBurgerClose: '#6c7075',
  headerModelCardName: '#182028',
  headerModelCardDescription: '#6c7075',
  headerModelCardPrice: '#182028',
  headerLine: '#a5adba',
} as const;

const colorsHisun = {
  headerBackground: '#333',
  headerNav: '#fff',
  headerNavHover: 'rgba(255, 255, 255, 0.6)',
  headerPhone: '#fff',
  headerPhoneHover: 'rgba(255, 255, 255, 0.7)',
  headerPhoneButton: '#fff',
  headerPhoneButtonHover: 'rgba(255, 255, 255, 0.7)',
  headerBurger: '#fff',
  headerBurgerClose: '#858585',
  headerModelCardName: '#fff',
  headerModelCardDescription: '#adadad',
  headerModelCardPrice: '#fff',
  headerLine: '#a5adba',
} as const;

const colorsJac = {
  headerBackground: '#fff',
  headerNav: '#1d1d1b',
  headerNavHover: 'rgba(29, 29, 27, 0.6)',
  headerPhone: '#636466',
  headerPhoneHover: '#1d1d1b',
  headerPhoneButton: '#1d1d1b',
  headerPhoneButtonHover: '#02405b',
  headerBurger: '#1d1d1b',
  headerBurgerClose: '#636466',
  headerModelCardName: '#1d1d1b',
  headerModelCardDescription: '#636466',
  headerModelCardPrice: '#1d1d1b',
  headerLine: '#a5adba',
} as const;

const colorsSollers = {
  headerBackground: '#fff',
  headerNav: '#000',
  headerNavHover: '#F47C30',
  headerPhone: '#f47c30',
  headerPhoneHover: '#ffae62',
  headerPhoneButton: '#000',
  headerPhoneButtonHover: '#ffae62',
  headerBurger: '#4c4c4c',
  headerBurgerClose: '#4c4c4c',
  headerModelCardName: '#000',
  headerModelCardDescription: '#4c4c4c',
  headerModelCardPrice: '#000',
  headerLine: '#a5adba',
} as const;

const colorsJetour = {
  headerBackground: '#fff',
  headerNav: '#000',
  headerNavHover: 'rgba(0, 0, 0, 0.6)',
  headerPhone: '#68a598',
  headerPhoneHover: '#628788',
  headerPhoneButton: '#000',
  headerPhoneButtonHover: '#628788',
  headerBurger: '#4c4c4c',
  headerBurgerClose: '#4c4c4c',
  headerModelCardName: '#000',
  headerModelCardDescription: '#4c4c4c',
  headerModelCardPrice: '#000',
  headerLine: '#a5adba',
} as const;

const colorsZeekr = {
  headerBackground: '#fff',
  headerNav: '#121215',
  headerNavHover: '#f76400',
  headerPhone: '#f76400',
  headerPhoneHover: '#db5a02',
  headerPhoneButton: '#121215',
  headerPhoneButtonHover: '#db5a02',
  headerBurger: '#121215',
  headerBurgerClose: '#121215',
  headerModelCardName: '#121215',
  headerModelCardDescription: '#121215',
  headerModelCardPrice: '#f76400',
  headerLine: '#121215',
} as const;

export const componentColors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'sollers':
      return colorsSollers;
    case 'jetour':
      return colorsJetour;
    case 'zeekr':
      return colorsZeekr;
    default:
      return colorsB2b;
  }
})();

export enum SubmenuTypes {
  Models = ':MODELS:',
}
