import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

interface Props {
  mouseLeaveDelay?: number;
}

export function useHover({ mouseLeaveDelay = 0 }: Props): {
  isHovering: boolean;
  setIsHovering: Dispatch<SetStateAction<boolean>>;
  getHoverProps: () => { onMouseEnter: () => void; onMouseLeave: () => void };
} {
  const router = useRouter();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const mouseLeaveTimerId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsHovering(false));

    return () =>
      router.events.off('routeChangeStart', () => setIsHovering(false));
  }, []);

  function onMouseEnter(): void {
    if (mouseLeaveTimerId.current) {
      clearTimeout(mouseLeaveTimerId.current);
    }
    setIsHovering(true);
  }

  function onMouseLeave(): void {
    mouseLeaveTimerId.current = setTimeout(() => {
      setIsHovering(false);
    }, mouseLeaveDelay);
  }

  function getHoverProps(): {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  } {
    return {
      onMouseEnter,
      onMouseLeave,
    };
  }

  return { isHovering, setIsHovering, getHoverProps };
}
