import React from 'react';
import styled from 'styled-components';

import { HeaderVariables } from '@/layout/components/Header/constants';
import MenuItem from '@/layout/components/Header/components/MobileMenu/components/MenuItem';
import MenuColumn from '@/layout/components/Header/components/MenuColumn';
import { colors } from '@/constants/theme';
import { bodyIconMap } from '@/constants/icons';

import { CarsMenuProps } from './types';

function CarsMenu({ menuItem }: CarsMenuProps) {
  return (
    <MenuItem
      label={menuItem.label.replace(HeaderVariables.Cars, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      {menuItem.children.map((item, index) => (
        <MenuColumnWrapper key={index}>
          <MenuColumn
            title={item.label}
            menu={item.children.map((item) => ({
              title: item.label,
              link: item.link ?? '',
              isNewTab: item.isNewTab,
              icon: bodyIconMap[item.label],
            }))}
          />
        </MenuColumnWrapper>
      ))}
    </MenuItem>
  );
}

export default CarsMenu;

const MenuColumnWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${colors.gray200};
  }
`;
