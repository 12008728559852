import React from 'react';

import Dropdown from '@/layout/components/DealerWebHeader/components/MenuList/components/Dropdown';
import Menu from '@/layout/components/DealerWebHeader/components/Menu';

import { LinkDropdownProps } from './types';

function LinkDropdown({
  label,
  link,
  isNewTab,
  menu,
  onClick,
}: LinkDropdownProps) {
  return (
    <Dropdown label={label} link={link} isNewTab={isNewTab} onClick={onClick}>
      <Menu menu={menu} />
    </Dropdown>
  );
}

export default LinkDropdown;
