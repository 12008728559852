import React from 'react';
import styled, { css } from 'styled-components';

import ChevronDownIcon from '@/assets/svg/chevron-down.svg';
import Link from '@/components/Link';
import { useHover } from '@/hooks/useHover';
import { colors } from '@/constants/theme';

import { OtherDropdownProps } from './types';

function OtherDropdown({ items }: OtherDropdownProps) {
  const { isHovering, getHoverProps } = useHover({
    mouseLeaveDelay: 300,
  });

  return (
    <DropDown {...getHoverProps()}>
      <DropDownToggle type="button" isOpen={isHovering}>
        <DropdownToggleText>Еще</DropdownToggleText>
        <DropdownToggleIcon>
          <ChevronDownIcon />
        </DropdownToggleIcon>
      </DropDownToggle>

      <DropDownMenu isOpen={isHovering}>
        {items.map((item, index) => (
          <DropDownMenuItem key={index}>
            <DropDownMenuLink
              to={item.link}
              target={item.isNewTab ? '_blank' : '_self'}
            >
              {item.label}
            </DropDownMenuLink>
          </DropDownMenuItem>
        ))}
      </DropDownMenu>
    </DropDown>
  );
}

export default OtherDropdown;

const DropDown = styled.div`
  position: relative;
  display: flex;
  z-index: 201;
`;

const DropdownToggleText = styled.span``;

const DropdownToggleIcon = styled.span`
  margin-left: 14px;
  transition: transform 0.15s;
`;

const DropDownToggle = styled.button<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 21px 25px;

  color: ${colors.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  z-index: 91;
  transition: 0.15s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${(props) => props.theme.white};
      color: ${(props) => props.theme.main};

      ${DropdownToggleIcon} {
        transform: rotate(180deg);

        svg {
          path {
            stroke: ${(props) => props.theme.main};
          }
        }
      }
    `};
`;

const DropDownMenu = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  z-index: 2;
  width: auto;
  padding: 12px 0;
  text-align: left;

  font-size: 16px;
  line-height: 24px;
  background: ${(props) => props.theme.white};

  opacity: 0;
  visibility: hidden;

  box-shadow: ${(props) => props.theme.blackShadow300};

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const DropDownMenuItem = styled.li``;

const DropDownMenuLink = styled(Link)`
  display: block;
  padding: 8px 30px 8px 20px;
  color: ${(props) => props.theme.black200};
  font-weight: 700;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 20px;
  border: none;

  white-space: nowrap;

  &:hover {
    color: ${(props) => props.theme.main};
    background: ${(props) => props.theme.white_1};
    border: none;
  }
`;
