import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { componentColors } from '@/layout/components/DealerWebFooter/constants';

import { MenuItemProps } from './types';

function ChildrenLink({
  label,
  link,
  isNewTab,
  children,
  onClick,
}: MenuItemProps) {
  return (
    <Component>
      <TitleLink
        to={link}
        target={`${isNewTab ? '_blank' : '_self'}`}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
          onClick?.(link, event)
        }
      >
        {label}
      </TitleLink>

      {children &&
        children.length > 0 &&
        children.map((node, index) => (
          <ChildrenLink key={index} onClick={onClick} {...node} />
        ))}
    </Component>
  );
}

export default ChildrenLink;

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
`;

const TitleLink = styled(Link)`
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${componentColors.footerNavChild};

  &:hover {
    color: ${componentColors.footerNavChildHover};
  }
`;
