import React from 'react';

import Models from '@/layout/components/DealerWebHeader/components/Models';
import Dropdown from '@/layout/components/DealerWebHeader/components/MenuList/components/Dropdown';

import { ModelsDropdownProps } from './types';

function ModelsDropdown({
  label,
  link,
  isNewTab,
  models,
  onClick,
}: ModelsDropdownProps) {
  return (
    <Dropdown label={label} link={link} isNewTab={isNewTab} onClick={onClick}>
      <Models models={models} />
    </Dropdown>
  );
}

export default ModelsDropdown;
