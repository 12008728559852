import React from 'react';
import styled from 'styled-components';

import Rating from '@/components/Rating';
import { colors } from '@/constants/theme';

interface Props {
  name: string;
  url?: string;
  rating: number;
  reviewsCount: number;
}

function Dealer({ name, url, rating, reviewsCount }: Props) {
  return (
    <Component>
      <Link href={url}>{name}</Link>
      <RatingLink href={url}>
        <RatingWrapper>
          <Stars>
            <Rating count={5} value={rating} />
          </Stars>
          <Reviews>
            <ReviewsCount>{reviewsCount}</ReviewsCount>{' '}
          </Reviews>
        </RatingWrapper>
      </RatingLink>
    </Component>
  );
}

export default Dealer;

const Component = styled.div``;

const Link = styled.a`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${colors.white};
    border-bottom: 1px solid ${colors.white};
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  align-content: center;
`;

const Stars = styled.div`
  display: flex;
  align-items: center;
`;

const Reviews = styled.div`
  flex: 0 1 auto;
  margin-left: 10px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 24px;
  transition: 0.15s;
  white-space: nowrap;
  text-decoration: underline;
`;

const ReviewsCount = styled.span``;

const RatingLink = styled.a`
  &:hover {
    ${Reviews} {
      text-decoration: none;
    }
  }
`;
