import React from 'react';
import styled from 'styled-components';

import FooterTop from './components/FooterTop';
import FooterBottom from './components/FooterBottom';

function DealerWebFooter() {
  return (
    <Component id="footer">
      <FooterTop />
      <FooterBottom />
    </Component>
  );
}

export default DealerWebFooter;

const Component = styled.footer``;
