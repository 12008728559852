import React from 'react';
import styled from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { componentColors } from '@/layout/components/DealerWebFooter/constants';

function FooterInfo() {
  const text = useSettingItem('FOOTER_DEALER_WEB_BOTTOM_TEXT');

  return <Component dangerouslySetInnerHTML={{ __html: text ?? '' }} />;
}

export default FooterInfo;

const Component = styled.div`
  flex: 0 0 55%;
  font-size: 14px;
  line-height: 14px;
  color: ${componentColors.footerBottomText};
`;
