export enum HeaderVariables {
  Brands = ':BRANDS:',
  Banner = ':BANNER:',
  Phone = ':PHONE:',
  Call = ':CALL:',
  Models = ':MODELS:',
  Cars = ':CARS:',
  Service = ':SERVICE:',
  Services = ':SERVICES:',
  ServiceOnline = ':SERVICE_ONLINE:',
}
