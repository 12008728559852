import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { useIsomorphicLayoutEffect } from '@tager/web-core';
import { MenuItemType } from '@tager/web-modules';

import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import Link from '@/components/Link';
import { useDealerWebLinkClick } from '@/layout/hooks';
import {
  componentColors,
  SubmenuTypes,
} from '@/layout/components/DealerWebHeader/constants';
import useSettingItem from '@/hooks/useSettingItem';

import ModelsDropdown from './components/ModelsDropdown';
import OtherDropdown from './components/OtherDropdown';
import LinkDropdown from './components/LinkDropdown';

function MenuList() {
  const [isMount, setMount] = useState<boolean>(true);
  const headerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];
  const [otherHeaderMenu, setOtherHeaderMenu] = useState<MenuItemType[]>([]);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const navRef = useRef<HTMLElement | null>(null);
  const dealerWebModels = useSettingItem('HEADER_DEALER_WEB_MODELS');
  const { handleClickLink } = useDealerWebLinkClick();

  const truncate = useCallback(() => {
    if (!componentRef.current || !navRef.current) {
      return;
    }

    const childNodes = Array.from(
      navRef.current.children
    ) as Array<HTMLElement>;

    for (let node of childNodes) {
      node.hidden = true;
    }

    if (childNodes.length === 0) {
      return;
    }

    for (let index = 0; index < childNodes.length; index++) {
      const item = childNodes[index];
      const nextItem = childNodes[index + 1];

      if (nextItem) {
        item.hidden = false;
        nextItem.hidden = false;

        const nextItemRect = nextItem.getBoundingClientRect();
        const componentRect = componentRef.current.getBoundingClientRect();

        nextItem.hidden = true;

        if (Math.round(nextItemRect.right) >= Math.round(componentRect.right)) {
          item.hidden = true;

          if (index > 0) {
            childNodes[index - 1].hidden = false;
          }

          break;
        }
      }
    }

    const hiddenItems = childNodes.filter((node) => node.hidden).length - 1;

    if (hiddenItems && childNodes.length > hiddenItems) {
      childNodes[childNodes.length - 1].hidden = false;
    }

    let indexes: Array<number> = [];

    childNodes.forEach((item, index) => {
      if (item.hidden && headerMenu[index]) {
        indexes.push(index);
      }
    });

    setOtherHeaderMenu(indexes.map((index) => headerMenu[index]));
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isMount) {
      setMount(false);
    }

    truncate();

    window.addEventListener('resize', truncate);

    return () => {
      window.removeEventListener('resize', truncate);
    };
  }, [truncate, isMount]);

  return (
    <Component ref={componentRef}>
      <Nav ref={navRef}>
        {headerMenu.map((item, index) => {
          const isModelsDropdown = item.label.includes(SubmenuTypes.Models);

          if (
            isModelsDropdown &&
            dealerWebModels &&
            dealerWebModels.length > 0
          ) {
            return (
              <MenuItemWrapper key={index}>
                <ModelsDropdown
                  label={item.label.replace(SubmenuTypes.Models, '').trim()}
                  link={item.link ?? ''}
                  isNewTab={item.isNewTab}
                  models={dealerWebModels.map(
                    ({ image, name, description, price, link, isNewTab }) => ({
                      image,
                      name: name ?? '',
                      description: description ?? '',
                      price: price ?? '',
                      link: link ?? '',
                      isNewTab,
                      onClick: handleClickLink,
                    })
                  )}
                  onClick={handleClickLink}
                />
              </MenuItemWrapper>
            );
          }

          if (item.children && item.children.length !== 0) {
            return (
              <MenuItemWrapper key={index}>
                <LinkDropdown
                  label={item.label}
                  link={item.link ?? ''}
                  isNewTab={item.isNewTab}
                  menu={item.children.map(({ label, children }) => ({
                    title: label ?? '',
                    menu:
                      children && children.length !== 0
                        ? children.map(({ label, link, isNewTab }) => ({
                            title: label ?? '',
                            link: link ?? '',
                            isNewTab,
                            onClick: handleClickLink,
                          }))
                        : [],
                  }))}
                  onClick={handleClickLink}
                />
              </MenuItemWrapper>
            );
          }

          return (
            <MenuItemWrapper key={index}>
              <MenuItem
                to={item.link ?? ''}
                target={item.isNewTab ? '_blank' : '_self'}
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => handleClickLink(item.link, event)}
              >
                {item.label}
              </MenuItem>
            </MenuItemWrapper>
          );
        })}

        {otherHeaderMenu.length > 0 && (
          <MenuItemWrapper>
            <OtherDropdown
              menu={otherHeaderMenu.map(({ label, link, isNewTab }) => ({
                label:
                  label.indexOf(':') === -1
                    ? label
                    : label.substring(0, label.indexOf(':')).trim(),
                link: link ?? '',
                isNewTab,
              }))}
              onClick={handleClickLink}
            />
          </MenuItemWrapper>
        )}
      </Nav>
    </Component>
  );
}

export default MenuList;

const Component = styled.div`
  flex: 1;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
`;

const MenuItemWrapper = styled.div`
  padding: 0 12.5px;
`;

const MenuItem = styled(Link)`
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  color: ${componentColors.headerNav};

  &:hover {
    color: ${componentColors.headerNavHover};
  }

  &[hidden] {
    display: none;
  }
`;
