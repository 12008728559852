import { getTheme } from '@/constants/theme';
import { ReservedButtonVariant } from '@/components/Button/types';

export const getButtonLinkVariant = (
  isMobile?: boolean
): ReservedButtonVariant => {
  const theme = getTheme();

  switch (theme) {
    case 'b2b':
      return !isMobile ? 'outlined' : 'contained';
    case 'hisun':
      return 'outlined100';
    case 'jac':
      return !isMobile ? 'outlined' : 'contained';
    case 'jetour':
      return !isMobile ? 'outlined' : 'contained';
    case 'sollers':
      return !isMobile ? 'outlined' : 'contained';
    case 'zeekr':
      return 'outlined';
    default:
      return 'outlined';
  }
};
