import React from 'react';
import styled from 'styled-components';

import Dealer from '@/layout/components/Footer/components/Main/components/Dealer';
import { useTypedSelector } from '@/store/store';
import { selectDealers } from '@/store/reducers/dealers';

const Dealers = () => {
  const dealers = useTypedSelector(selectDealers);

  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      {dealers.map((dealer, index) => (
        <DealerItem key={index}>
          <Dealer
            name={dealer.name ?? ''}
            url={dealer.url ?? ''}
            rating={dealer.rating}
            reviewsCount={dealer.reviewsCount}
          />
        </DealerItem>
      ))}
    </Component>
  );
};

export default Dealers;

const Component = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 16px;
`;

const DealerItem = styled.li``;
